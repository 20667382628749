img{
    max-width: 100%;
    border-radius: 1px;
}

.img-news{
    border-radius: 1px;
    width:  480px;
    height: 200px;
    object-fit: cover;
}

.img-raised{
    box-shadow: $box-shadow-raised;
}
